import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { environment } from '../../../environments/environment';
import { OAuth2AuthenticationDto } from '@next-solutions/next-solutions-base';
import {BirthdayUserService} from '../../_services/birthday-user.service';

@Component({
  selector: 'app-logout',
  template: ``,
  styles: [``],
})
export class LogoutComponent implements OnInit {
  constructor(private loginComponent: LoginComponent,
              protected birthdayUserService: BirthdayUserService) {
  }

  ngOnInit() {
    window.sessionStorage.removeItem('token');
    const dlgRef = this.loginComponent.showLoginModal();
    dlgRef.afterClosed().subscribe(() => {
      // this.onMouseLeave();
      this.birthdayUserService.clear();
    });
  }
}
