import {DialogTypeEnum, NsCustomDialogDataConfig} from '@next-solutions/next-solutions-base';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {NsCustomDialogNewComponent} from '../base/ns-custom-dialog-new.component';
import {Injectable} from '@angular/core';

@Injectable()
export class DialogConfirmService {

  constructor(
    private dialog: MatDialog,
    private transService: TranslateService) {
  }



  public strFormat(str: string, replacement?: string[]) {
    let a = this.transService.instant(str);
    if (replacement === null || replacement === undefined || replacement.length === 0) {
      return a;
    }
    replacement.forEach((value, index) => {
      a = a.replace('{' + index + '}', this.transService.instant(value));
    });
    return a;
  }

  public showConfirmInputDialog(str1: string, replacement: string[],
                                confirmInputDialogConfig: MatDialogConfig<NsCustomDialogDataConfig> | undefined = undefined,
                                strOkText: string = 'common.OK', strCancelTex: string = 'common.Cancel',strCustomClass?:string
  ) {
    const str = this.strFormat(str1, replacement);
    const strOk = this.transService.instant(strOkText);
    const strCancel = this.transService.instant(strCancelTex);
    const re = /\./gi;
    const customClass = (strCustomClass?strCustomClass:str1).replace(re, '-');

    return this.dialog.open(NsCustomDialogNewComponent,
      confirmInputDialogConfig ? confirmInputDialogConfig : {
        width: '500px',

        data: {
          customClass,
          msg: str,
          type: DialogTypeEnum.INPUT_CONFIRM,
          btnOKString: strOk,
          btnCancelString: strCancel,
        } as NsCustomDialogDataConfig,
      });
  }

}
