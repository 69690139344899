export enum SuperStatusEnum {
  _ = 'super.status.all',
  _DRAFT = 'super.status.draft',
  _ACCEPTED = 'super.status.accepted',
  _REJECTED = 'super.status.rejected',
  _CANCEL = 'super.status.cancel',

}


