import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  AlignEnum,
  ApiService,
  AuthoritiesService,
  BaseAddEditLayout,
  ColumnFields,
  ColumnTypes,
  DateUtilService,
  UtilsService
} from '@next-solutions/next-solutions-base';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {Utils} from '../../utils/utils';
import {environment} from '../../../environments/environment';
import {UserModel} from '../../models/userModel';
import {GenderEnum} from '../../enums/gender.enum';
import {BirthdayUserService} from '../../_services/birthday-user.service';


@Component({
  selector: 'app-user-birthday',
  templateUrl: './user-birthday.component.html',
  styleUrls: ['./user-birthday.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserBirthDayComponent extends BaseAddEditLayout implements OnInit {

  moduleName = 'user';
  userColumns: ColumnFields[] = [];
  userColumnsMonth: ColumnFields[] = [];

  constructor(private formBuilder: FormBuilder,
              private apiService: ApiService,
              protected translateService: TranslateService,
              protected toastr: ToastrService,
              protected authoritiesService: AuthoritiesService,
              protected activatedRoute: ActivatedRoute,
              protected location: Location,
              protected utilsService: UtilsService,
              private router: Router,
              protected dateUtilService: DateUtilService,
              private dialogRef: MatDialogRef<UserBirthDayComponent>,
              protected birthdayUserService: BirthdayUserService) {
    super(activatedRoute, location, translateService, utilsService, authoritiesService);
    this.addEditForm = this.formBuilder.group({
      usersDay: [],
      usersMonth: []
    });

    this.addEditForm.patchValue({
      usersDay: birthdayUserService.users.map(u => u),
      usersMonth: birthdayUserService.usersMonth.map(u => u)
    });
    this.userColumns = [
      {
        columnDef: 'stt',
        header: 'stt',
        alignHeader: AlignEnum.CENTER,
        align: AlignEnum.CENTER,
        cell: (e) => Utils.getPosition(e, this.addEditForm.value.usersDay),
        title: (e) => Utils.getPosition(e, this.addEditForm.value.usersDay),
      },
      {
        columnDef: 'avatar', header: 'avatar',
        columnType: ColumnTypes.IMG,
        title: (e: UserModel) => `${e.avatar ? environment.BASE_FILE_URL + e.avatar : ''}`,
        cell: (e: UserModel) => `${e.avatar ? environment.BASE_FILE_URL + e.avatar : ''}`,
        className: 'mat-column-avatar',
      },
      {
        columnDef: 'code', header: 'code',
        title: (e: UserModel) => `${e.code ? e.code : ''}`,
        cell: (e: UserModel) => `${e.code ? e.code : ''}`,
        className: 'mat-column-code',
      },
      {
        columnDef: 'name',
        header: 'name',
        cell: (e: UserModel) => `${e.name ? e.name : ''}`,
        title: (e: UserModel) => `${e.name ?  e.name : ''}`,
      },
      {
        columnDef: 'username', header: 'username',
        title: (e: UserModel) => `${e.username}`,
        cell: (e: UserModel) => `${e.username}`,
        className: 'mat-column-username',
      },
      {
        columnDef: 'phone', header: 'phone',
        title: (e: UserModel) => `${e.phone}`,
        cell: (e: UserModel) => `${e.phone}`,
        className: 'mat-column-phone',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'email', header: 'email',
        title: (e: UserModel) => `${e.email ? e.email : ''}`,
        cell: (e: UserModel) => `${e.email ? e.email : ''}`,
        className: 'mat-column-email',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'gender', header: 'gender',
        title: (e: UserModel) => `${e.gender ? this.utilsService.getEnumValueTranslated(GenderEnum, `${e.gender}`) : ''}`,
        cell: (e: UserModel) => `${e.gender ? this.utilsService.getEnumValueTranslated(GenderEnum, `${e.gender}`) : ''}`,
        className: 'mat-column-gender',
      },
      {
        columnDef: 'birthday', header: 'birthday',
        title: (e: UserModel) => `${e.birthday ? dateUtilService.convertDateToDisplayServerTime(e.birthday.replace(' ','T')) : ''}`,
        cell: (e: UserModel) => `${e.birthday ? dateUtilService.convertDateToDisplayServerTime(e.birthday.replace(' ','T')) : ''}`,
        className: 'mat-column-birthday',
        isExpandOptionColumn: () => true,
      }
    ];

    this.userColumnsMonth = [
      {
        columnDef: 'stt',
        header: 'stt',
        alignHeader: AlignEnum.CENTER,
        align: AlignEnum.CENTER,
        cell: (e) => Utils.getPosition(e, this.addEditForm.value.usersMonth),
        title: (e) => Utils.getPosition(e, this.addEditForm.value.usersMonth),
      },
      {
        columnDef: 'avatar', header: 'avatar',
        columnType: ColumnTypes.IMG,
        title: (e: UserModel) => `${e.avatar ? environment.BASE_FILE_URL + e.avatar : ''}`,
        cell: (e: UserModel) => `${e.avatar ? environment.BASE_FILE_URL + e.avatar : ''}`,
        className: 'mat-column-avatar',
      },
      {
        columnDef: 'code', header: 'code',
        title: (e: UserModel) => `${e.code ? e.code : ''}`,
        cell: (e: UserModel) => `${e.code ? e.code : ''}`,
        className: 'mat-column-code',
      },
      {
        columnDef: 'name',
        header: 'name',
        cell: (e: UserModel) => `${e.name ? e.name : ''}`,
        title: (e: UserModel) => `${e.name ?  e.name : ''}`,
      },
      {
        columnDef: 'username', header: 'username',
        title: (e: UserModel) => `${e.username}`,
        cell: (e: UserModel) => `${e.username}`,
        className: 'mat-column-username',
      },
      {
        columnDef: 'phone', header: 'phone',
        title: (e: UserModel) => `${e.phone}`,
        cell: (e: UserModel) => `${e.phone}`,
        className: 'mat-column-phone',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'email', header: 'email',
        title: (e: UserModel) => `${e.email ? e.email : ''}`,
        cell: (e: UserModel) => `${e.email ? e.email : ''}`,
        className: 'mat-column-email',
        isExpandOptionColumn: () => true,
      },
      {
        columnDef: 'gender', header: 'gender',
        title: (e: UserModel) => `${e.gender ? this.utilsService.getEnumValueTranslated(GenderEnum, `${e.gender}`) : ''}`,
        cell: (e: UserModel) => `${e.gender ? this.utilsService.getEnumValueTranslated(GenderEnum, `${e.gender}`) : ''}`,
        className: 'mat-column-gender',
      },
      {
        columnDef: 'birthday', header: 'birthday',
        title: (e: UserModel) => `${e.birthday ? dateUtilService.convertDateToDisplayServerTime(e.birthday.replace(' ','T')) : ''}`,
        cell: (e: UserModel) => `${e.birthday ? dateUtilService.convertDateToDisplayServerTime(e.birthday.replace(' ','T')) : ''}`,
        className: 'mat-column-birthday',
        isExpandOptionColumn: () => true,
      }
    ];

  }

  ngOnInit(): void {
    console.log(this.addEditForm);
  }
  onCancel() {
    this.dialogRef.close();
  }
}
