export const environment = {
  production: false,
  BASE_URL: 'https://pg.mutosi.com:6033',
  BASE_SCHEDULE_URL: 'https://pg.mutosi.com:6036',
  BASE_FILE_URL: 'https://pg.mutosi.com:6033/files/',
  BASE_AUTHORIZATION_URL: 'https://pg.mutosi.com:9999',
  CLIENT_ID: 'PG',
  CLIENT_SECRET: '134,;1,\';dlf\'\';,f\';3,f\';,a\'df;,a\';f,a\'w;f,a\'w;,f\'a;3,f\'q;3,fq\'f,\'34,fq\'34,f\'q4;,fq\'4f,q\'3',
  PAGE_SIZE: 10,
  PAGE_SIZE_OPTIONS: [5, 10, 20, 30, 50, 100],
  DEFAULT_LANGUAGE: 'vi',
  DEFAULT_THEME: 'default',
  LANGS: [
    {id: 1, code: 'vi', name: 'Việt Nam', logo: 'assets/Flags/vi.ico'},
    {id: 2, code: 'en', name: 'English', logo: 'assets/Flags/en.ico'},
    {id: 3, code: 'my', name: 'Myamar', logo: 'assets/Flags/my.ico'}],
  API_DATE_FORMAT: 'yyyy-MM-dd HH:mm:ss.SSS\'Z\'',
  API_DATE_FORMAT_WEB: 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'',
  DIS_DATE_FORMAT: 'dd/MM/yyyy',
  DIALOG_LOGO: '',
  LOADING_IMAGE: 'assets/Flags/mutoshi_image.png',
  NO_IMAGE_AVAILABLE: 'assets/Flags/NO_IMAGE.png',
  IS_EMBEDDED: false,
  EMBEDDED_URLs: [],
  EXPAND_HEADER_BUTTON: { // ButtonFields
    columnDef: 'expandColumn',
    color: 'warn',
    icon: 'filter_list',
    click: '',
    title: 'common.title.expand.column',
  },
  IS_LABEL_OUTSIDE: false,
  INTEGER_MAX_VALUE: '2147483647',
  mutosi: 'mutosi',
};
