<div class = "user_birthday">
  <div class="dialog_wrapper">
    <div class="dialog_header" >
      <div class="dialog_title">
        <h3>Danh sách nhân viên sinh nhật</h3>
      </div>
    </div>
    <mat-tab-group>
      <mat-tab label="Trong ngày">
        <div class="content">
          <div class="dialog_content_user_birthday">
            <form class="form" [formGroup]="addEditForm"
                  fxLayout="row wrap" class="row-wrap-padding">
              <ns-smart-table formControlName="usersDay"
                              name="usersDay"
                              [columns]="userColumns"
                              [isStickyHeader]="true"
                              [isSticky]="true"
                              [moduleName]="moduleName"
                              (clickAction)="onRowButtonClick($event)"
                              fxLayout="column" fxFlex="100%"
              >
              </ns-smart-table>
            </form>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Trong tháng">
          <div class="content">
            <div class="dialog_content_user_birthday">
              <form class="form" [formGroup]="addEditForm"
                    fxLayout="row wrap" class="row-wrap-padding">
                <ns-smart-table formControlName="usersMonth"
                                name="usersMonth"
                                [columns]="userColumnsMonth"
                                [isStickyHeader]="true"
                                [isSticky]="true"
                                [moduleName]="moduleName"
                                (clickAction)="onRowButtonClick($event)"
                                fxLayout="column" fxFlex="100%"
                >
                </ns-smart-table>
              </form>
            </div>
          </div>
      </mat-tab>


    </mat-tab-group>
    <div class="dialog_footer center">
      <div>
        <button mat-button class="error"
                (click)="onCancel()">
          <i class="fas fa-cancel"></i>{{'common.Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>






</div>
