import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ApiService} from '@next-solutions/next-solutions-base';


@Injectable()
export class AuthenticationService {
  constructor(private apiService: ApiService) {
  }

  login(loginPayload: any) {
    const headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');
    return this.apiService.post('/oauth/token', loginPayload, {headers}, environment.BASE_AUTHORIZATION_URL);
  }

  isAuthenticated() {
    if (window.sessionStorage.getItem('token') != null) {
      return true;
    }
    return false;
  }
}
