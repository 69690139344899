import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import {FileSaverModule} from 'ngx-filesaver';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './material.module';
import {CommonModule} from '@angular/common';
import {
  NextSolutionsModules,
  SingletonTranslateService,
  UtilsService
} from '@next-solutions/next-solutions-base';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {NsMonthPicker} from '../base/month-picker/ns-month-picker.component';
import {NsImageViewComponent} from '../base/ns-image-view.component';
import {DialogImageViewComponent} from '../base/dialog-image-view.component';
import {NsUploadFileCustomComponent} from '../base/ns-upload-file-custom.component';
import {MatButtonModule} from '@angular/material/button';
import {NsDatePickerCustom} from '../base/datepicker/ns-date-picker-custom.component';
import {NsRangeDatePickerCustom} from '../base/datepicker/ns-range-date-picker-custom.component';
import {NsMultiUploadFileCustomComponent} from '../base/ns-multi-upload-file-custom.component';
import {DialogCarouselComponent} from '../base/dialog-carousel.component';
import {NsCustomDialogNewComponent} from '../base/ns-custom-dialog-new.component';
import {NsMultiSelectAutocompleteCustom} from '../base/ns-multi-select-autocomplete-custom';
import {ThousandSeparatorNotDecimalPipe} from '../base/pipe/ThousandSeparatorNotDecimalPipe';

@NgModule({
  declarations: [
    NsMonthPicker,
    NsImageViewComponent,
    DialogImageViewComponent,
    NsUploadFileCustomComponent,
    NsDatePickerCustom,
    NsRangeDatePickerCustom,
    DialogCarouselComponent,
    NsMultiUploadFileCustomComponent,
    NsCustomDialogNewComponent,
    NsMultiSelectAutocompleteCustom,

    ThousandSeparatorNotDecimalPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FileSaverModule,
    FlexLayoutModule,
    MaterialModule,
    NextSolutionsModules,
    TranslateModule,
    MatButtonModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FileSaverModule,
    FlexLayoutModule,
    MaterialModule,
    NextSolutionsModules,
    NsMonthPicker,
    NsImageViewComponent,
    DialogImageViewComponent,
    NsUploadFileCustomComponent,
    NsDatePickerCustom,
    NsRangeDatePickerCustom,
    DialogCarouselComponent,
    NsMultiUploadFileCustomComponent,
    NsCustomDialogNewComponent,
    NsMultiSelectAutocompleteCustom,

    ThousandSeparatorNotDecimalPipe
  ],
  providers: [UtilsService,
    ThousandSeparatorNotDecimalPipe],
})
export class SharedModule {
  constructor(private translate: TranslateService, protected singletonTranslateService: SingletonTranslateService) {
    if (!window.sessionStorage.getItem('lang' + environment.CLIENT_ID) || window.sessionStorage.getItem('lang' + environment.CLIENT_ID) === '') {
      window.sessionStorage.setItem('lang' + environment.CLIENT_ID, environment.DEFAULT_LANGUAGE);
      translate.setDefaultLang(environment.DEFAULT_LANGUAGE);
      singletonTranslateService.currentLanguage.next(environment.DEFAULT_LANGUAGE);
    } else {
      singletonTranslateService.currentLanguage.next('' + window.sessionStorage.getItem('lang' + environment.CLIENT_ID));
    }
    singletonTranslateService.currentLanguage$.subscribe((lang: string) => {
      this.translate.use(lang);
    });
  }
}
