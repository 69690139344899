import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { LogoutComponent } from '../components/logout/logout.component';
import { AuthoritiesResolverService } from '@next-solutions/next-solutions-base';

const routes: Routes = [
  { path: '', component: DashboardComponent, resolve: { me: AuthoritiesResolverService } },
  { path: 'home', component: DashboardComponent, resolve: { me: AuthoritiesResolverService } },
  { path: 'dashboard', component: DashboardComponent, resolve: { me: AuthoritiesResolverService } },
  {
    path: 'cms',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/cms.module').then(m => m.CmsModule),
  },
  {
    path: 'management-sale-progress',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/msp.module').then(m => m.MspModule),
  },
  {
    path: 'time-keeping',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/tk.module').then(m => m.TkModule),
  },
  {
    path: 'monitor',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/monitor.module').then(m => m.MonitorModule),
  },
  {
    path: 'promotion',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/promotion.module').then(m => m.PromotionModule),
  },
  {
    path: 'display',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/display.module').then(m => m.DisplayModule),
  },
  {
    path: 'display-virtual',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/display-virtual.module').then(m => m.DisplayVirtualModule),
  },
  {
    path: 'revenue',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/revenue.module').then(m => m.RevenueModule),
  },
  {
    path: 'add-user-revenue',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/add-user-revenue.module').then(m => m.AddUserRevenueModule),
  },
  {
    path: 'inventory',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/inventory.module').then(m => m.InventoryModule),
  },
  {
    path: 'kpi',
    data: { breadcrumb: 'menu.kpi.' },
    loadChildren: () => import('../modules/kpi.module').then(m => m.KpiModule),
  },
  {
    path: 'survey',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/survey.module').then(m => m.SurveyModule),
  },
  {
    path: 'turnover',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/turnover.module').then(m => m.TurnoverModule),
  },
  {
    path: 'report',
    data: { breadcrumb: '' },
    loadChildren: () => import('../modules/report.module').then(m => m.ReportModule),
  },
  { path: 'logout', component: LogoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      // preloadingStrategy: PreloadAllModules,
    },
  ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
