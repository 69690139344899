import {SuperStatusEnum} from "../enums/super.status.enum";

export abstract class ModelUtilService {
  isAccepted(e: any | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._ACCEPTED
        ;
    }
    return true;
  }
  isDisableAccept(e: any | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._ACCEPTED
        ;
    }
    return true;
  }

  isDisableReject(e: any | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }
  isDisableSchedule(e: any | null): boolean {
    if (e) {
      return SuperStatusEnum['_' + e.status] === SuperStatusEnum._REJECTED;
    }
    return true;
  }

  abstract hasAddEditPermission(e: any | null): boolean;

  abstract hasViewDetailPermission(): boolean

  abstract hasAcceptPermission(): boolean;

  abstract hasRejectPermission(): boolean;

  abstract onAccept(e: any | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void): void;

  abstract onReject(e: any | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void): void;
}
