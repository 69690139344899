import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Embed, IEmbedConfiguration, models, service as PBIService} from 'powerbi-client';
import {AuthenticationService} from '../../_services/authentication.service';
import {HttpClient} from '@angular/common/http';
import {
  ApiService,
  AuthoritiesService,
  BaseTableLayout,
  Menu,
  NavItem,
  UtilsService,
} from '@next-solutions/next-solutions-base';
import {ActivatedRoute} from '@angular/router';
import {NavService} from '../../_services/nav.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseTableLayout implements OnInit, OnChanges {
  component: Embed | null | undefined;
  accessToken = '';
  tokenType = models.TokenType.Embed;
  embedUrl = '';
  type = 'report'; // type powerbi
  reportId = '';
  loadPowerBIFail = false;

  constructor(@Inject('PowerBIService') public powerBIService: PBIService.Service,
              protected activatedRoute: ActivatedRoute,
              protected authoritiesService: AuthoritiesService,
              private navService: NavService,
              private apiService: ApiService,
              private http: HttpClient,
              private utilsService: UtilsService,
              private authenticationService: AuthenticationService) {
    super(activatedRoute, authoritiesService);
  }

  ngOnInit() {
    // this.loadPowerBI().then();
  }

  // async loadPowerBI() {
  //   if (this.authenticationService.isAuthenticated()) {
  //     const response = await this.apiService.post('/power-bi/embed', null).toPromise()
  //       .catch(error => {
  //         this.loadPowerBIFail = true;
  //         this.utilsService.showErrorToarst('validation.powerbi.embed.fail');
  //       }) as any;
  //     if (response) {
  //       this.loadPowerBIFail = false;
  //       this.accessToken = response.accessToken;
  //       this.embedUrl = response.embedUrl;
  //       this.reportId = response.reportId;
  //
  //       const {accessToken, tokenType, embedUrl, type, reportId} = this;
  //       const config: IEmbedConfiguration = {accessToken, tokenType, embedUrl, type, id: reportId};
  //       if (this.validateOptions(accessToken, embedUrl)) {
  //         this.embed(document.getElementById('powerbiFrame'), config);
  //       }
  //     }
  //   }
  // }


  ngOnChanges(changes: SimpleChanges): void {
    // const {accessToken, tokenType, embedUrl, type, id} = changes;
    //
    // if (accessToken.previousValue === accessToken.currentValue
    //   || embedUrl.previousValue === embedUrl.currentValue) {
    //   return;
    // }
    //
    // if (this.validateOptions(accessToken.currentValue, embedUrl.currentValue)) {
    //
    //   /* check if change value was provided in changes
    //    to prevent error accessing to a property of undefined */
    //   const config: IEmbedConfiguration = {
    //     accessToken: accessToken && accessToken.currentValue,
    //     tokenType: tokenType ? this.getTokenType(tokenType.currentValue) : models.TokenType.Aad,
    //     embedUrl: embedUrl && embedUrl.currentValue,
    //     type: type && type.currentValue,
    //     id: id && id.currentValue,
    //   };
    //
    //   this.embed(document.getElementById('powerbiFrame'), config);
    // } else if (this.component) {
    //   this.reset(document.getElementById('powerbiFrame'));
    // }

  }

  // validateOptions(accessToken: string, embedUrl: string): boolean {
  //   if (!(typeof embedUrl === 'string' && embedUrl.length > 0)
  //     || !(typeof accessToken === 'string' && accessToken.length > 0)
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }
  //
  // getTokenType(tokenType: string): models.TokenType {
  //   if (!tokenType || tokenType.length < 0) {
  //     return models.TokenType.Aad;
  //   } else {
  //     tokenType = tokenType.charAt(0).toUpperCase() + tokenType.toLowerCase().slice(1);
  //     return models.TokenType[tokenType];
  //   }
  // }
  //
  // embed(element: HTMLElement | null, config: IEmbedConfiguration) {
  //   if (element)
  //     this.component = this.powerBIService.embed(element, config);
  // }
  //
  // reset(element: HTMLElement | null) {
  //   if (element)
  //     this.powerBIService.reset(element);
  //   this.component = null;
  // }
  //
  // reloadPowerBI() {
  //   this.loadPowerBI().then();
  // }
}
