import {AfterViewInit, Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {Router} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {OverlayContainer} from '@angular/cdk/overlay';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {
  ApiService,
  AuthoritiesService,
  DateUtilService,
  Menu,
  NavItem,
  OAuth2AuthenticationDto,
  Page,
} from '@next-solutions/next-solutions-base';
import {environment} from '../environments/environment';
import {TopNavComponent} from './_helpers/top-nav/top-nav.component';
import {NavService} from './_services/nav.service';
import {AuthenticationService} from './_services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {defineFullCalendarElement} from '@fullcalendar/web-component';
import {HttpParams} from '@angular/common/http';
import {BirthdayUserService} from './_services/birthday-user.service';
import {UserBirthDayComponent} from './components/user-birthday/user-birthday.component';

// make the <full-calendar> element globally available by calling this function at the top-level
defineFullCalendarElement();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TopNavComponent) topNav!: TopNavComponent;
  @ViewChild('appDrawer', {static: true}) appDrawer: ElementRef | undefined;
  @HostBinding('class') componentCssClass: any | undefined;
  subscription: Subscription | undefined;

  // @HostListener('window:message', ['$event'])
  // onMessage(e: any) {
  //   if (environment.EMBEDDED_URLs.indexOf(e.origin) < 0) {
  //     return;
  //   }
  //   const data = JSON.parse(e.data);
  //   if (data.token) {
  //     this.navService.closeNav();
  //     environment.IS_EMBEDDED = true;
  //     window.sessionStorage.setItem('lang' + environment.CLIENT_ID, data.langBilling);
  //     window.sessionStorage.setItem(data.key, JSON.stringify(data.token));
  //     window.sessionStorage.setItem('distributorCode', data.distributorCode);
  //     this.loginComponent.resetInfo();
  //     this.loginComponent.hideLoginModal();
  //     this.router.navigate([data.routerNavigate.toString()]).then();
  //   } else {
  //     this.router.navigate(['logout']).then();
  //   }
  // }

  constructor(public overlayContainer: OverlayContainer, private navService: NavService, private router: Router,
              private authenticationService: AuthenticationService, private apiService: ApiService,
              private loginComponent: LoginComponent, private location: Location,
              protected authoritiesService: AuthoritiesService, private matDialog: MatDialog,
              protected dateUtilService: DateUtilService,protected birthdayUserService: BirthdayUserService) {
  }

  ngOnInit() {

    // reload component
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.navService.appDrawer = this.appDrawer;

    if (this.authenticationService.isAuthenticated()) {
      if (this.location.isCurrentPathEqualTo('/')) {
        this.router.navigate(['home']).then();
      }
    } else {
      this.router.navigate(['logout']).then();
    }
  }

  ngAfterViewInit() {
    this.authoritiesService.me$.subscribe(async (me: OAuth2AuthenticationDto) => {
      if (!me || !me.userAuthentication || !!this.navService.navItems) {
        return;
      }
      // if (me.userAuthentication.principal?.mustChangePassword) {
      //   this.matDialog.open(UserBirthdayComponent, {
      //     width: '80%',
      //     height: '80%'
      //   });
      // }
      this.apiService.getJSON<NavItem[]>('assets/Menus.json').subscribe((data: NavItem[]) => {
        let menus = [];
        if (me.userAuthentication && me.userAuthentication.principal && me.userAuthentication.principal.roles) {
          for (const role of me.userAuthentication.principal.roles) {
            if (role.menus)
              for (const menu of role.menus) {
                menus.push(menu);
              }
          }
        }
        menus = menus.filter(menu => menu.appType === 'WEB');
        data = this.getRoleMenu(data, menus);
        this.navService.navItems = data;
        this.onMouseLeave();
      });

      const params = new HttpParams()
        .set('status', 'ACCEPTED')
        .set('pageNumber', '1')
        .set('nowDate', this.dateUtilService.convertDateToStringCurrentGMT(new Date()) ?? '')
        .set('pageSize', environment.INTEGER_MAX_VALUE);
      this.apiService.get<Page>('/user', params).subscribe(data => {
        if (data && data.content) {
          this.birthdayUserService.setTitle(false);
          this.birthdayUserService.add(data.content);
        }
      });

      const paramsMonth = new HttpParams()
        .set('status', 'ACCEPTED')
        .set('pageNumber', '1')
        .set('birthdayUserInMonth', this.dateUtilService.convertDateToStringCurrentGMT(new Date()) ?? '')
        .set('pageSize', environment.INTEGER_MAX_VALUE);
      this.apiService.get<Page>('/user', paramsMonth).subscribe(data => {
        if (data && data.content) {
          this.birthdayUserService.addUserMonth(data.content);
          this.birthdayUserService.setTitle(true);
        }
      });

    });
  }

  getRoleMenu(navItems: NavItem[], menus: Menu[]) {
    const result = [];
    for (const navItem of navItems) {
      const flag = menus.filter(menu => !navItem.roleMenu || menu.code === navItem.roleMenu);
      if (flag && flag.length > 0) {
        if (navItem.children && navItem.children.length > 0) {
          navItem.children = this.getRoleMenu(navItem.children, menus);
        }
        result.push(navItem);
      } else if ((!menus || menus.length === 0) && !navItem.roleMenu) {
        result.push(navItem);
      }
    }
    return result;
  }

  changeTheme(theme: any) {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item !== 'cdk-overlay-container');
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
    this.componentCssClass = theme;
    const body = document.getElementsByTagName('body')[0];
    body.removeAttribute('class');
    body.classList.add(theme);

    if (theme === 'zeus') {
      const div = document.createElement('div') as HTMLDivElement;
      div.id = 'night_stars';
      body.prepend(div);
    } else {
      const div = document.getElementById('night_stars');
      if (div != null) {
        body.removeChild(div);
      }
    }
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.subscription?.unsubscribe();
  }

  get navItems() {
    return this.navService.navItems;
  }

  onMouseEnter() {
    // this.navService.navItems?.forEach(item => {
    //   item.isOnlyIcon = false;
    // });
  }

  onMouseLeave() {
    // if (this.navService.navItems) {
    //   this.navService.navItems.forEach(item => {
    //     // item.isOnlyIcon = true;
    //   });
    // }
  }

  get imgSrc() {
    return environment.LOADING_IMAGE;
  }

  get isEmbedded() {
    return environment.IS_EMBEDDED;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth < 990 ? this.navService.closeNav() : this.navService.openNav();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any) {
    if (event.target.className !== 'username' && !event.target.closest('.username')) {
      this.topNav.userName.nativeElement.classList.add('off');
    }
  }
}
