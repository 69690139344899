import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'thousandSeparatorNotDecimal'
})
export class ThousandSeparatorNotDecimalPipe implements PipeTransform {

  transform(nStr: any): any {
    if (nStr === '') {
      return '';
    }
    let x;
    let x1;
    let x2;
    let rgx;
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x[1];
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + (x2 ? `.${x2}` : '');
  }
}
