import { Injectable } from '@angular/core';
import { ApiService, AuthoritiesService, UtilsService } from '@next-solutions/next-solutions-base';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserModel } from '../models/userModel';
import { environment } from '../../environments/environment';
import { ModelUtilService } from './model-util.service';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UserUtilService extends ModelUtilService {
  constructor(protected authoritiesService: AuthoritiesService,
              protected apiService: ApiService,
              protected utilsService: UtilsService,
              protected translateService: TranslateService,
              private datePipe: DatePipe) {
    super();
  }

  hasAddEditPermission(e: UserModel | null): boolean {
    if (e && e.id) {
      return this.authoritiesService.hasAuthority('patch/user/{id}')
        && this.authoritiesService.hasAuthority('patch/sso/user/{id}');
    } else {
      return this.authoritiesService.hasAuthority('post/user')
        && this.authoritiesService.hasAuthority('post/sso/user');
    }
  }

  hasViewDetailPermission(): boolean {
    return this.authoritiesService.hasAuthority('get/user/{id}');
  }

  hasAcceptPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/user/{id}/accept')
      && this.authoritiesService.hasAuthority('post/sso/user/active');
  }

  hasRejectPermission(): boolean {
    return this.authoritiesService.hasAuthority('patch/user/{id}/reject')
      && this.authoritiesService.hasAuthority('post/sso/user/deactive');
  }

  onAccept(e: UserModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.patch('/user/' + e.id + '/accept', null);
      this.utilsService.execute(method, onSuccessFunc, 'common.accept.success',
        'common.confirm.accept', ['user.']);
    }
  }

  onReject(e: UserModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const strOk = this.translateService.instant('common.ok');
      const strCancel = this.translateService.instant('common.cancel');
      const strReasonReject = this.translateService.instant('common.reject.reason');
      const dialogConfig: MatDialogConfig<any> = {
        data: {
          customClass: 'reject_reason_dialog',
          msg: strReasonReject,
          type: 'INPUT_CONFIRM',
          btnOKString: strOk,
          btnCancelString: strCancel,
        },
      };
      this.utilsService.showConfirmInputDialog('common.reject.reason', [], dialogConfig)
        .afterClosed().subscribe(result => {
        if (result && result.value) {
          const params = new HttpParams().set('rejectReason', result.value);
          this.apiService.patch(`/user/${e.id}/reject`, null, { params })
            .subscribe(data => {
                onSuccessFunc(data, 'common.reject.success');
              },
              error => {
                this.utilsService.showError(error);
              });
        }
      });
    }
  }

  onActiveAccount(e: UserModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.post('/user/active', e.username, {}, environment.BASE_AUTHORIZATION_URL);
      this.utilsService.execute(method, onSuccessFunc, 'common.active-account.success',
        'common.confirm.active-account', ['user.']);
    }
  }

  hasResetPasswordPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/sso/user/reset-password');
  }

  hasActiveAccountPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/sso/user/active');
  }

  hasEditRolePermission(e: UserModel | null): boolean {
    return this.authoritiesService.hasAuthority('get/sso/user/{username}/role')
      && this.hasAddEditPermission(e);
  }

  onResetPassword(e: UserModel | null, onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void) {
    if (e) {
      const method = this.apiService.post('/user/reset-password', e.username, {}, environment.BASE_AUTHORIZATION_URL);
      this.utilsService.execute(method, onSuccessFunc, 'common.reset-password.success',
        'common.confirm.reset-password', ['user.']);
    }
  }

  hasExportDataPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/export-data/user');
  }

  hasImportExcelPermission(): boolean {
    return this.authoritiesService.hasAuthority('post/import-data/user');
  }

  hasDownloadTemplatePermission(): boolean {
    return this.authoritiesService.hasAuthority('post/download-template/user');
  }

  convertDateToDisplayServerTime(date: string): string | null {
    return this.datePipe.transform(date ? date.replace('Z', '') : '', 'yyyy-MM-dd') + 'T00:00:00';
  }

  convertDateToStringCurrentGMT(date: Date): string | null {
    return this.datePipe.transform(date, 'yyyy-MM-dd') + 'T00:00:00';
  }
}
