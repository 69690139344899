import {AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';

export class ChangePasswordService implements Validators {
  static asyncNewPasswordValidationConfirm(compareControlName: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {

      const compareControl = (control.parent! as FormGroup)?.controls[compareControlName];
      if (compareControl && compareControl.invalid) {
        compareControl.updateValueAndValidity();
      }

      let error = null;
      if (compareControl && compareControl.value === control.value) {
        error = null;
      } else if (compareControl && compareControl.value && control.value) {
        error = {checkConfirmFalse: {checkConfirmFalse: true}};
      }
      return of(error);
    };
  }

  static newPasswordValidationConfirm(compareControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const compareControl = (control.parent! as FormGroup)?.controls[compareControlName];
      if (compareControl && compareControl.invalid) {
        compareControl.updateValueAndValidity();
      }

      let error = null;
      if (compareControl && compareControl.value === control.value) {
        error = null;
      } else if (compareControl && compareControl.value && control.value) {
        error = {checkConfirmFalse: {checkConfirmFalse: true}};
      }
      return error;
    };
  }

  static newPasswordSameOldPasswordConfirm(compareControlName: string = 'oldPassword'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const compareControl = (control.parent! as FormGroup)?.controls[compareControlName];
      if (compareControl && compareControl.invalid) {
        compareControl.updateValueAndValidity();
      }

      let error;
      if (compareControl && compareControl.value !== control.value) {
        error = null;
      } else {
        error = {sameOldPassword: {sameOldPassword: true}};
      }
      return error;
    };
  }
}
