import {SelectModel, UtilsService} from '@next-solutions/next-solutions-base';
import {QuillModules, QuillToolbarConfig} from 'ngx-quill';
import {Observable} from 'rxjs';
import {formatNumber} from '@angular/common';

export class Utils {

  static getNumberFormatDisplay = (value: number | any): string => {
    if (isNaN(value)) {
      return '';
    }
    return formatNumber(value, 'en-US').replace(/\,/g, '.');
  }

  static isEmptyList(list: any) {
    return !list || (Array.isArray(list) && list.length === 0);
  }

  static getPosition(e: any, arr: any[] | undefined) {
    return !!arr && arr.length > 0 ? (arr.indexOf(e) + 1).toString() : '';
  }

  static getAllOptionSelectModel() {
    return new SelectModel(' ', 'common.all');
  }

  static customExecuteErrorHandle(utilsService: UtilsService,
                                  method: () => Observable<any>,
                                  onSuccessFunc: (this: void, d: any, onSuccessMessage?: string) => void,
                                  onSuccessMessage: string,
                                  confirmMsg: string,
                                  confirmMsgParamsFormat: string[] = [],
                                  onErrorFunc: (err: any) => void,
                                  confirmDialogButtonOk: string = 'common.OK', confirmDialogButtonCancel: string = 'common.Cancel',
  ) {
    utilsService.showConfirmDialog(confirmMsg, confirmMsgParamsFormat, '', [], undefined, confirmDialogButtonOk, confirmDialogButtonCancel)
      .afterClosed().subscribe(result => {
      if (result && result.value) {
        method().subscribe((data: any) => {
          onSuccessFunc(data, onSuccessMessage);
        }, error1 => {
          if (error1 !== '401') {
            onErrorFunc(error1);
          }
        });
      }
    });
  }

  static createQuillModuleIgnoreImage(): QuillModules {
    return {
      toolbar: {
        container: [
          [{font: []}],
          [{size: ['small', false, 'large', 'huge']}],
          ['bold', 'italic', 'underline', 'strike'],
          [{header: 1}, {header: 2}],
          [{color: []}, {background: []}],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{align: []}],
          ['link'/*, 'image'*/],
        ],
      },
      // imageResize: true,
    };
  }

  static createQuillModuleWithImage(): QuillModules {
    return {
      toolbar: {
        container: [
          [{font: []}],
          [{size: ['small', false, 'large', 'huge']}],
          ['bold', 'italic', 'underline', 'strike'],
          [{header: 1}, {header: 2}],
          [{color: []}, {background: []}],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{align: []}],
          ['link', 'image'],
        ] as QuillToolbarConfig,
      },
      imageResize: true,
    } as QuillModules;
  }

  static formatPhoneNumber(phoneNumberString?: string): string | null | undefined {
    if (!phoneNumberString) {
      return phoneNumberString;
    }
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  static removeVietnameseTones(str?: string): string | null | undefined {
    if (!str) {
      return str;
    }
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, ' ');
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    return str;
  }

}
