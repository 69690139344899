import { Injectable } from '@angular/core';
import {UserModel} from '../models/userModel';

@Injectable()
export class BirthdayUserService {
  title = false;
  users: UserModel[] = [];
  usersMonth: UserModel[] = [];
  count = 0;

  add(users: UserModel[]) {
    this.users = [...users];
  }

  addUserMonth(usersMonth: UserModel[]) {
    this.usersMonth = [...usersMonth];
  }

  get() {
    return this.users;
  }

  getUserMonth() {
    return this.usersMonth;
  }


  clear() {
    this.users = [];
  }

  setTitle(title:boolean){
    this.title = title;
  }

  addCount(){
    this.count +=1;
  }
}
